var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE &&
      _vm.currentTables.length
        ? _c(
            "v-banner",
            {
              staticClass: "mb-5",
              attrs: { "single-line": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              to: {
                                name: "snapshot-tables",
                                params: {
                                  oid: _vm.$route.params.oid,
                                  sid: _vm.$route.params.sid,
                                  iid: _vm.$route.params.iid,
                                  snid: _vm.$route.params.snid,
                                },
                              },
                              text: "",
                              color: "primary",
                            },
                          },
                          [_vm._v(" Browse tables ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              href: "https://docs.nuvolos.cloud/features/database-integration/view-tables",
                              target: "_blank",
                              text: "",
                              color: "primary",
                            },
                          },
                          [_vm._v(" How to handle data in Nuvolos? ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2058746379
              ),
            },
            [
              _c(
                "v-avatar",
                {
                  attrs: { slot: "icon", color: "white", size: "30" },
                  slot: "icon",
                },
                [_c("v-icon", { attrs: { color: "info" } }, [_vm._v("info")])],
                1
              ),
              _vm._v(" This dataset contains dataset tables. "),
            ],
            1
          )
        : _vm._e(),
      _vm.isSpaceWaking
        ? _c(
            "v-alert",
            { staticClass: "ma-4 mb-0", attrs: { text: "", color: "info" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c("v-icon", { attrs: { color: "info", right: "" } }, [
                        _vm._v("mdi-information"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "grow" }, [
                    _vm._v(
                      " Files are not shown in the navigator until every instance has been woken up. Applications will be started as soon as their instance is woken up. "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("increase-storage-quota"),
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            disabled: _vm.isSpaceResting || _vm.isSpaceWaking,
          },
        },
        [
          _c(
            "v-card-title",
            [
              _c("TheSnapshotMoveAndCopyFiles", {
                attrs: {
                  selectedFiles: _vm.filesToMoveOrCopy,
                  operationType: _vm.copyOrMoveOperation,
                  folderTypeSelected: _vm.folderTypeSelected,
                },
                on: {
                  clearCopyAndMoveFiles: function ($event) {
                    return _vm.clearCopyAndMoveFiles()
                  },
                  removeFileFromMoveCopyList: function ($event) {
                    return _vm.removeFileFromMoveCopyList($event.fid)
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex w-100" },
                [
                  _c(
                    "span",
                    { staticClass: "secondary--text font-weight-bold" },
                    [_vm._v("Files")]
                  ),
                  _c("v-spacer"),
                  !_vm.fetchingSnapshotQuota &&
                  _vm.snapshotQuota.bytes_pcent !== null &&
                  _vm.isDevelopment &&
                  _vm.snapshotFilesystemPrefix &&
                  !_vm.isSpaceRested
                    ? _c(
                        "the-snapshot-usage-statistics-dialog",
                        { attrs: { changeFileType: _vm.changeFileType } },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pointer",
                                          class: { "grey lighten-4": hover },
                                          attrs: { elevation: "0" },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c("v-progress-linear", {
                                                staticClass: "mb-2",
                                                attrs: {
                                                  rounded: "",
                                                  height: "6",
                                                  value:
                                                    _vm.quotaPercentageUsage,
                                                  color:
                                                    _vm.quotaPercentageUsage <=
                                                    90
                                                      ? "primary"
                                                      : "error",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "caption font-weight-medium d-flex align-center",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        `${
                                                          _vm.quotaPercentageUsage
                                                        }% of ${_vm.humanFileSize(
                                                          _vm.snapshotQuota
                                                            .bytes_quota
                                                        )}`
                                                      ) +
                                                      " used "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        right: "",
                                                        small: "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-open-in-new")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              313771711
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.filesFetching
                ? _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: { type: "table" },
                  })
                : _vm.isSpaceRested && _vm.isDevelopment
                ? _c(
                    "v-alert",
                    {
                      staticClass: "ma-4 mb-0",
                      attrs: { text: "", color: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "info", right: "" } },
                                [_vm._v("mdi-information")]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", { staticClass: "grow" }, [
                            _c("div", [_c("b", [_vm._v("Rested state")])]),
                            _vm._v(
                              " Files are not available in the Current State of this instance. Wake up the space to work with its files or browse snapshots directly to download/distribute them. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : !_vm.snapshotFilesystemPrefix &&
                  !_vm.isColdStorage &&
                  !_vm.isSpaceInAnyRestState
                ? _c(
                    "v-banner",
                    { attrs: { "single-line": "" } },
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: { slot: "icon", color: "white", size: "60" },
                          slot: "icon",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                "x-large": "",
                                icon: "info",
                                color: "info",
                              },
                            },
                            [_vm._v("info")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _vm.isInstanceEditor && _vm.isDevelopment
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-weight-bold secondary--text",
                                  },
                                  [_vm._v("Archived state")]
                                ),
                                _c("p", [
                                  _vm._v(
                                    " Files are not available in the Current State of this instance. To access files, you may either "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToSnapshots()
                                        },
                                      },
                                    },
                                    [_vm._v("restore")]
                                  ),
                                  _vm._v(
                                    " a snapshot to Current State or browse files of snapshots. "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.isDevelopment && !_vm.isSpaceRested
                                ? _c("file-upload", {
                                    attrs: {
                                      endpoint: _vm.endpoint,
                                      disabled:
                                        _vm.filesFetching ||
                                        _vm.isSpaceArchived,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _vm.isDevelopment && !_vm.isSpaceRested
                                ? _c("create-folder", {
                                    attrs: {
                                      disabled:
                                        _vm.filesFetching ||
                                        _vm.isSpaceArchived,
                                      path: _vm.endpoint,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-switch", {
                            staticClass: "my-0 pa-0 mx-2",
                            attrs: {
                              "hide-details": "",
                              label: "Hidden Files",
                            },
                            model: {
                              value: _vm.showHidden,
                              callback: function ($$v) {
                                _vm.showHidden = $$v
                              },
                              expression: "showHidden",
                            },
                          }),
                          _vm.currentFiles.length
                            ? _c("v-divider", {
                                staticClass: "mx-3",
                                attrs: { vertical: "" },
                              })
                            : _vm._e(),
                          _vm.selected.length > 0
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "justify-start mr-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addToStaging(
                                          _vm.selected,
                                          "selected"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("share"),
                                    ]),
                                    _vm._v(
                                      " stage selected (" +
                                        _vm._s(_vm.selected.length) +
                                        ") "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadFile(_vm.selected)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-download"),
                                    ]),
                                    _vm._v(" download selected "),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.tableData.length > 0
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFile([
                                        _vm.currentFolder,
                                      ])
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-download"),
                                  ]),
                                  _vm._v(" download all "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentFiles.length
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "", bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "caption mr-2",
                                                  attrs: {
                                                    color: "secondary",
                                                    icon: "",
                                                    dark: "",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("more_vert"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1301944704
                                  ),
                                  model: {
                                    value: _vm.multipleFilesActionsMenu,
                                    callback: function ($$v) {
                                      _vm.multipleFilesActionsMenu = $$v
                                    },
                                    expression: "multipleFilesActionsMenu",
                                  },
                                },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "py-4",
                                      attrs: { dense: "", nav: "" },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "justify-start",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.currentFiles.length,
                                                    color: "secondary",
                                                    block: "",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addToStaging(
                                                        _vm.currentFiles,
                                                        "selected"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v("share")]
                                                  ),
                                                  _vm._v(
                                                    " stage all (" +
                                                      _vm._s(
                                                        _vm.currentFiles.length
                                                      ) +
                                                      ") "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "justify-start",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.selected.length,
                                                    color: "secondary",
                                                    block: "",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addToStaging(
                                                        _vm.selected,
                                                        "selected"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v("share")]
                                                  ),
                                                  _vm._v(
                                                    " stage selected (" +
                                                      _vm._s(
                                                        _vm.selected.length
                                                      ) +
                                                      ") "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-3" }),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _vm.selected.length === 0
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "justify-start",
                                                      attrs: {
                                                        color: "secondary",
                                                        text: "",
                                                        block: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadFile(
                                                            [_vm.currentFolder]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [_vm._v("mdi-download")]
                                                      ),
                                                      _vm._v(" download all "),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "justify-start",
                                                      attrs: {
                                                        color: "secondary",
                                                        text: "",
                                                        block: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadFile(
                                                            _vm.selected
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [_vm._v("mdi-download")]
                                                      ),
                                                      _vm._v(
                                                        " download selected "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                          _vm.selected.length > 10
                                            ? _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { right: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "grey lighten-1",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-information"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2792354461
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Downloading multiple files is possible only for a maximum of 10 files."
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.currentSpace.vimeo_enabled &&
                                      _vm.isSpaceAdmin &&
                                      !_vm.isSpaceArchived &&
                                      _vm.isAllVideo(_vm.selected)
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "vimeo-upload-dialog",
                                                    {
                                                      attrs: {
                                                        videos: _vm.selected,
                                                      },
                                                      on: {
                                                        dismissed: function (
                                                          $event
                                                        ) {
                                                          return _vm.fetchFiles()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "justify-start",
                                                          attrs: {
                                                            block: "",
                                                            color: "secondary",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.multipleFilesActionsMenu = false
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-filmstrip-box-multiple"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " add to video library "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isDevelopment && !_vm.isSpaceRested
                                        ? [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "justify-start",
                                                        attrs: {
                                                          disabled:
                                                            !_vm.selected
                                                              .length,
                                                          color: "secondary",
                                                          text: "",
                                                          block: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addToMoveCopyList(
                                                              _vm.selected,
                                                              "move",
                                                              true
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-folder-move"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" move "),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "justify-start",
                                                        attrs: {
                                                          disabled:
                                                            !_vm.selected
                                                              .length,
                                                          color: "secondary",
                                                          text: "",
                                                          block: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addToMoveCopyList(
                                                              _vm.selected,
                                                              "copy",
                                                              true
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "content_copy"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" copy "),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-divider", {
                                              staticClass: "my-3",
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.isDevelopment &&
                                      !_vm.isColdStorage &&
                                      !_vm.isSpaceRested
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c("delete-button", {
                                                attrs: {
                                                  objectType: "file",
                                                  buttonName:
                                                    _vm.getDeleteLabel,
                                                  protocol: "POST",
                                                  "request-body": {
                                                    fids: _vm.selectedFileIds,
                                                    mode: 0,
                                                  },
                                                  apiURL: "/files/delete_async",
                                                  disabled:
                                                    _vm.selected.length === 0,
                                                  isAsyncDeletion: true,
                                                  showDialog: true,
                                                  warningText:
                                                    _vm.confirmQuestion(0),
                                                  fetchString:
                                                    "snapshotStore/fetchCurrentFiles",
                                                  objectName:
                                                    _vm.selectedFileNames.toString(),
                                                },
                                                on: {
                                                  error: function ($event) {
                                                    return _vm.errorMessage(
                                                      $event.error
                                                    )
                                                  },
                                                  deleting: function ($event) {
                                                    return _vm.emptySelected(
                                                      !$event.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSpaceAdmin &&
                                      !_vm.isHomeFilesArea &&
                                      _vm.currentSpaceType !==
                                        _vm.spaceTypes.RESEARCH_SPACE &&
                                      !_vm.isColdStorage &&
                                      !_vm.isSpaceRested
                                        ? [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c("delete-button", {
                                                  attrs: {
                                                    objectType: "file",
                                                    buttonName:
                                                      _vm.getDeleteForOthersLabel,
                                                    protocol: "POST",
                                                    "request-body": {
                                                      fids: _vm.selectedFileIds,
                                                      mode: 2,
                                                    },
                                                    apiURL:
                                                      "/files/delete_async",
                                                    disabled:
                                                      _vm.selected.length === 0,
                                                    isAsyncDeletion: true,
                                                    showDialog: true,
                                                    warningText:
                                                      _vm.confirmQuestion(2),
                                                    fetchString:
                                                      "snapshotStore/fetchCurrentFiles",
                                                    objectName:
                                                      _vm.selectedFileNames.toString(),
                                                  },
                                                  on: {
                                                    error: function ($event) {
                                                      return _vm.errorMessage(
                                                        $event.error
                                                      )
                                                    },
                                                    deleting: function (
                                                      $event
                                                    ) {
                                                      return _vm.emptySelected(
                                                        !$event.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm.isDevelopment &&
                                            _vm.isMasterInstance &&
                                            !_vm.isSpaceRested
                                              ? _c(
                                                  "v-list-item",
                                                  [
                                                    _c("delete-button", {
                                                      attrs: {
                                                        objectType: "file",
                                                        buttonName:
                                                          "Delete for all",
                                                        protocol: "POST",
                                                        "request-body": {
                                                          fids: _vm.selectedFileIds,
                                                          mode: 1,
                                                        },
                                                        apiURL:
                                                          "/files/delete_async",
                                                        disabled:
                                                          _vm.selected
                                                            .length === 0,
                                                        isAsyncDeletion: true,
                                                        showDialog: true,
                                                        warningText:
                                                          _vm.confirmQuestion(
                                                            1
                                                          ),
                                                        fetchString:
                                                          "snapshotStore/fetchCurrentFiles",
                                                        objectName:
                                                          _vm.selectedFileNames.toString(),
                                                      },
                                                      on: {
                                                        error: function (
                                                          $event
                                                        ) {
                                                          return _vm.errorMessage(
                                                            $event.error
                                                          )
                                                        },
                                                        deleting: function (
                                                          $event
                                                        ) {
                                                          return _vm.emptySelected(
                                                            !$event.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c("v-text-field", {
                            staticClass: "mr-2",
                            attrs: {
                              "prepend-inner-icon": "mdi-filter",
                              autofocus: "",
                              "hide-details": "",
                              solo: "",
                              flat: "",
                              "background-color": "grey lighten-4",
                              dense: "",
                              label: "Filter files...",
                              clearable: "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              loading: _vm.filesFetching,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchFiles()
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("refresh")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Refresh Files")])]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "v-data-table",
                        {
                          staticClass: "shepherd-staging-step-1",
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.tableData,
                            "show-select": "",
                            "item-key": "fid",
                            options: _vm.tableOptions,
                            "footer-props": {
                              "items-per-page-options": _vm.itemsPerPageOptions,
                            },
                            search: _vm.search,
                            loading: _vm.filesFetching,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "no-data",
                                fn: function () {
                                  return [
                                    _vm.isDevelopment &&
                                    _vm.pathArray.length === 1 &&
                                    !_vm.currentFiles.length &&
                                    !_vm.isSpaceRested
                                      ? _c("span", [
                                          _vm._v(
                                            ' Currently you don\'t have files. Consider adding new files via the "Upload" button above or you can also '
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "https://docs.nuvolos.cloud/features/file-system-and-storage/synchronize-with-dropbox",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " set up Dropbox integration. "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            'Currently you don\'t have files. Use the "Hidden Files" switch above to display any hidden files.'
                                          ),
                                        ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "top",
                                fn: function ({
                                  options,
                                  pagination,
                                  updateOptions,
                                }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        !_vm.filesFetching &&
                                        (_vm.snapshotFilesystemPrefix ||
                                          _vm.isColdStorage ||
                                          _vm.isSpaceRested)
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-snackbar",
                                                  {
                                                    attrs: {
                                                      timeout:
                                                        _vm.snackbarTimeout,
                                                      color: "info",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.stageSuggestionSnackbar,
                                                      callback: function ($$v) {
                                                        _vm.stageSuggestionSnackbar =
                                                          $$v
                                                      },
                                                      expression:
                                                        "stageSuggestionSnackbar",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v("info")]
                                                    ),
                                                    _vm._v(
                                                      " Add uploaded files to stage to share with others? "
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addToStaging(
                                                              _vm.lastUploadedFiles,
                                                              "uploaded"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Stage")]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                          text: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.discardUploaded,
                                                        },
                                                      },
                                                      [_vm._v("Discard")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-sheet",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    attrs: {
                                                      color: "grey lighten-4",
                                                      rounded: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.filesFetching,
                                                          "offset-y": "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mx-2",
                                                                        attrs: {
                                                                          text: "",
                                                                          "e2e-snapshot-file-area":
                                                                            "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-uppercase",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.folderTypeSelected ===
                                                                                  _vm
                                                                                    .folderTypesLabels
                                                                                    .WORKSPACE_FILES
                                                                                  ? _vm
                                                                                      .userWorkAreas
                                                                                      .WORKSPACE
                                                                                  : _vm
                                                                                      .userWorkAreas
                                                                                      .PERSONAL
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              right:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "keyboard_arrow_down"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "v-list",
                                                          {
                                                            attrs: {
                                                              nav: "",
                                                              dense: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  right: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-list-item",
                                                                              _vm._g(
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.changeFileType(
                                                                                          _vm
                                                                                            .folderTypesLabels
                                                                                            .WORKSPACE_FILES
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Workspace"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Public files visible to all instance editors/viewers."
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            !_vm.isInstanceViewer
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      right: "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item",
                                                                                  _vm._g(
                                                                                    {
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.changeFileType(
                                                                                              _vm
                                                                                                .folderTypesLabels
                                                                                                .PERSONAL_FILES
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Personal"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Files visible to you only."
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-divider", {
                                                      attrs: { vertical: "" },
                                                    }),
                                                    _c("v-breadcrumbs", {
                                                      staticClass:
                                                        "ma-0 py-2 px-4",
                                                      attrs: {
                                                        large: "",
                                                        items: _vm.dirPaths,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function (
                                                              props
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$store.dispatch(
                                                                            "snapshotStore/setTreeLevel",
                                                                            {
                                                                              snid: _vm
                                                                                .$route
                                                                                .params
                                                                                .snid,
                                                                              level:
                                                                                props
                                                                                  .item
                                                                                  .index +
                                                                                1,
                                                                            }
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    props.item
                                                                      .index > 0
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "secondary--text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                props
                                                                                  .item
                                                                                  .text
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm
                                                                          .dirPaths
                                                                          .length ===
                                                                        1
                                                                      ? _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "secondary",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "folder"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "secondary",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-folder-open"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("v-spacer"),
                                        _c("v-data-footer", {
                                          staticClass: "no-border",
                                          attrs: {
                                            pagination: pagination,
                                            options: options,
                                            itemsPerPageOptions:
                                              _vm.itemsPerPageOptions,
                                            "items-per-page-text":
                                              "$vuetify.dataTable.itemsPerPageText",
                                          },
                                          on: {
                                            "update:options": updateOptions,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.short_id`,
                                fn: function ({ item }) {
                                  return [
                                    item.type === _vm.nuvolosObjectTypes.FOLDER
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "text-none",
                                            attrs: { text: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openDirectory(
                                                  item.short_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "secondary",
                                                },
                                              },
                                              [_vm._v("folder")]
                                            ),
                                            _vm._v(
                                              " " + _vm._s(item.short_id) + " "
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "offset-y": "",
                                              right: "",
                                              "min-width": "0",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "text-none",
                                                            attrs: { text: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.fetchIsEditableFile(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.fileIcon(
                                                                    item.type,
                                                                    item.short_id
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.short_id
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "showOnHover",
                                                              attrs: {
                                                                right: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-chevron-down"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              {
                                                staticClass: "py-4",
                                                attrs: { dense: "", nav: "" },
                                              },
                                              [
                                                item.short_id &&
                                                item.short_id
                                                  .toLowerCase()
                                                  .endsWith(".pdf")
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "pdf-viewer",
                                                              {
                                                                attrs: {
                                                                  pdfUrl:
                                                                    _vm.previewBlobUrl,
                                                                  longId:
                                                                    _vm.previewLongId,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "justify-start",
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      text: "",
                                                                      loading:
                                                                        _vm.downloadingFileForPreview,
                                                                      block: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.previewFile(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          left: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-eye"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " view "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.short_id &&
                                                item.short_id
                                                  .toLowerCase()
                                                  .endsWith(".ipynb")
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "notebook-viewer",
                                                              {
                                                                attrs: {
                                                                  notebookContent:
                                                                    _vm.notebookContent,
                                                                  longId:
                                                                    _vm.nbFile,
                                                                  loading:
                                                                    _vm.downloadingFileForPreview,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "justify-start",
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      text: "",
                                                                      block: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.previewNotebook(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          left: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-eye"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " view "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "justify-start",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              text: "",
                                                              block: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.downloadFile(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  left: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-download"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " download "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.isZipFile(item)
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "justify-start",
                                                                attrs: {
                                                                  loading:
                                                                    _vm.extractingZip,
                                                                  color:
                                                                    "secondary",
                                                                  text: "",
                                                                  block: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.extractZip(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-folder-zip-outline"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " extract all "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.isDevelopment &&
                                                !_vm.isSpaceArchived &&
                                                !_vm.isSpaceRested
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "the-snapshot-file-editor",
                                                              {
                                                                attrs: {
                                                                  endpoint:
                                                                    _vm.endpoint,
                                                                  fileData:
                                                                    item,
                                                                  language:
                                                                    _vm.fileLanguage(
                                                                      item
                                                                    ),
                                                                  isMarkdownFile:
                                                                    _vm.fileLanguage(
                                                                      item
                                                                    ) ===
                                                                    _vm
                                                                      .editorLanguages
                                                                      .md,
                                                                  isBlock: true,
                                                                  disabled:
                                                                    !_vm.isEditableFile(
                                                                      item
                                                                    ),
                                                                  loading:
                                                                    _vm.isEditableFileFetching,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.isDevelopment &&
                                                _vm.canEditInJupyter(item) &&
                                                !_vm.isSpaceArchived &&
                                                !_vm.isSpaceRested
                                                  ? _c(
                                                      "v-list-item",
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              "open-on-hover":
                                                                "",
                                                              "offset-x": "",
                                                              "nudge-top": "14",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  text: "",
                                                                                  block:
                                                                                    "",
                                                                                  color:
                                                                                    "secondary",
                                                                                },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  left: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-television-play"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "flex-grow-1 text-left",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Open in"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  right:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-chevron-right"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "v-list",
                                                              _vm._l(
                                                                _vm.jupyterApps,
                                                                function (app) {
                                                                  return _c(
                                                                    "v-list-item",
                                                                    {
                                                                      key: app.aid,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "justify-start",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "secondary",
                                                                              text: "",
                                                                              block:
                                                                                "",
                                                                              to: {
                                                                                name: "app-open",
                                                                                params:
                                                                                  {
                                                                                    aid: app.aid,
                                                                                    filePath:
                                                                                      _vm.createFilePath(
                                                                                        item,
                                                                                        app
                                                                                      ),
                                                                                    reset: true,
                                                                                  },
                                                                              },
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "mr-2 ml-n1",
                                                                              attrs:
                                                                                {
                                                                                  src: "https://dlcfc4rxk1sfk.cloudfront.net/nuvolos/app_logos/jupyter-logo.svg",
                                                                                  height:
                                                                                    "16px",
                                                                                  width:
                                                                                    "16px",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                app.long_id
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              },
                              {
                                key: `item.last_modified_timestamp`,
                                fn: function ({ item }) {
                                  return [
                                    _c("FileStatus", {
                                      attrs: { fileData: item },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: `item.size`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-end grey--text",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.humanFileSize(item.size))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.actions`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-2 showOnHover",
                                        attrs: {
                                          color: "secondary",
                                          small: "",
                                          outlined: "",
                                          text: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addToStaging(
                                              [item],
                                              "selected"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("share"),
                                        ]),
                                        _vm._v(" stage "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { left: "", "offset-x": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-3",
                                                        attrs: {
                                                          text: "",
                                                          color: "secondary",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.fetchIsEditableFile(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("more_horiz"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value:
                                            _vm.dropdownMenu[
                                              `file_${item.fid}`
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dropdownMenu,
                                              `file_${item.fid}`,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dropdownMenu[`file_${item.fid}`]",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            staticClass: "py-4",
                                            attrs: { nav: "", dense: "" },
                                          },
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "justify-start",
                                                        attrs: {
                                                          download:
                                                            item.type ===
                                                            _vm
                                                              .nuvolosObjectTypes
                                                              .FOLDER
                                                              ? `${item.short_id}.zip`
                                                              : item.short_id,
                                                          color: "secondary",
                                                          text: "",
                                                          block: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.downloadFile(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-download"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" download "),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            item.type !==
                                              _vm.nuvolosObjectTypes.FOLDER &&
                                            _vm.currentFileStatus(item) !==
                                              _vm.fileStatusTypes.UNSAVED
                                              ? _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              block: "",
                                                              color:
                                                                "secondary",
                                                              text: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showFileVersions(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-center justify-start w-100",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-file-compare"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " versions "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.currentSpace.vimeo_enabled &&
                                            _vm.isSpaceAdmin &&
                                            !_vm.isSpaceArchived &&
                                            item.is_video
                                              ? _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "vimeo-upload-dialog",
                                                          {
                                                            attrs: {
                                                              videos: [item],
                                                            },
                                                            on: {
                                                              dismissed:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.fetchFiles()
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  block: "",
                                                                  color:
                                                                    "secondary",
                                                                  text: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.closeMenu(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center justify-start w-100",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          left: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-filmstrip-box-multiple"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " add to video library "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isDevelopment &&
                                            !_vm.isSpaceRested
                                              ? [
                                                  item.short_id &&
                                                  item.short_id
                                                    .toLowerCase()
                                                    .endsWith(".pdf")
                                                    ? _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "pdf-viewer",
                                                                {
                                                                  attrs: {
                                                                    pdfUrl:
                                                                      _vm.previewBlobUrl,
                                                                    longId:
                                                                      _vm.previewLongId,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "justify-start",
                                                                      attrs: {
                                                                        color:
                                                                          "secondary",
                                                                        text: "",
                                                                        loading:
                                                                          _vm.downloadingFileForPreview,
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.previewFile(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              left: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-eye"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " view "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("v-divider", {
                                                    staticClass: "my-3",
                                                  }),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c("rename-button", {
                                                            attrs: {
                                                              selected: [item],
                                                              objectType:
                                                                "file",
                                                              dispatchFunction:
                                                                () => {
                                                                  _vm.$store.dispatch(
                                                                    "snapshotStore/fetchCurrentFiles",
                                                                    {
                                                                      id: _vm
                                                                        .$route
                                                                        .params
                                                                        .snid,
                                                                      route:
                                                                        _vm.$route,
                                                                      setFetchingStatus: true,
                                                                    }
                                                                  )
                                                                },
                                                            },
                                                            on: {
                                                              finished:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.emptySelected(
                                                                    $event.value
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "justify-start",
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                                text: "",
                                                                block: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addToMoveCopyList(
                                                                      [item],
                                                                      "move"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-folder-move"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" move "),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.isSpaceArchived
                                                    ? _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "the-snapshot-file-editor",
                                                                {
                                                                  attrs: {
                                                                    endpoint:
                                                                      _vm.endpoint,
                                                                    fileData:
                                                                      item,
                                                                    language:
                                                                      _vm.fileLanguage(
                                                                        item
                                                                      ),
                                                                    isMarkdownFile:
                                                                      _vm.fileLanguage(
                                                                        item
                                                                      ) ===
                                                                      _vm
                                                                        .editorLanguages
                                                                        .md,
                                                                    isBlock: true,
                                                                    disabled:
                                                                      !_vm.isEditableFile(
                                                                        item
                                                                      ),
                                                                    loading:
                                                                      _vm.isEditableFileFetching,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-list-item",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "flex-grow-1 justify-start",
                                                          attrs: {
                                                            color: "secondary",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addToMoveCopyList(
                                                                [item],
                                                                "copy"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "content_copy"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" copy "),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "open-on-hover": "",
                                                            "offset-x": "",
                                                            "nudge-top": "14",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "",
                                                                              },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-chevron-right"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            [
                                                              _c(
                                                                "copy-to-clipboard",
                                                                {
                                                                  attrs: {
                                                                    textToCopy:
                                                                      _vm.copyToClipboard(
                                                                        item
                                                                      ),
                                                                    buttonName: `Copy ${
                                                                      _vm.isFolder(
                                                                        item
                                                                      )
                                                                        ? "folder"
                                                                        : "file"
                                                                    } name`,
                                                                    type: "listItem",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "copy-to-clipboard",
                                                                {
                                                                  attrs: {
                                                                    textToCopy:
                                                                      _vm.copyToClipboard(
                                                                        item,
                                                                        true
                                                                      ),
                                                                    buttonName: `Copy ${
                                                                      _vm.isFolder(
                                                                        item
                                                                      )
                                                                        ? "folder"
                                                                        : "file"
                                                                    } path`,
                                                                    type: "listItem",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  item.type !==
                                                  _vm.nuvolosObjectTypes.FOLDER
                                                    ? _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    block: "",
                                                                    color:
                                                                      "secondary",
                                                                    text: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.duplicateFile(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-center justify-start w-100",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              left: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-content-duplicate"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " duplicate "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.isZipFile(item)
                                                    ? _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "justify-start",
                                                                  attrs: {
                                                                    loading:
                                                                      _vm.extractingZip,
                                                                    color:
                                                                      "secondary",
                                                                    text: "",
                                                                    block: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.extractZip(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        left: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-folder-zip-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " extract all "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("v-divider", {
                                                    staticClass: "my-3",
                                                  }),
                                                ]
                                              : _vm._e(),
                                            _vm.isDevelopment &&
                                            !_vm.isColdStorage &&
                                            !_vm.isSpaceRested
                                              ? _c(
                                                  "v-list-item",
                                                  {
                                                    attrs: {
                                                      "e2e-delete-file-for-me":
                                                        "",
                                                    },
                                                  },
                                                  [
                                                    _c("delete-button", {
                                                      attrs: {
                                                        objectType: "file",
                                                        buttonName:
                                                          _vm.getDeleteLabel,
                                                        protocol: "POST",
                                                        "request-body": {
                                                          fids: [item.fid],
                                                          mode: 0,
                                                        },
                                                        apiURL:
                                                          "/files/delete_async",
                                                        isAsyncDeletion: true,
                                                        showDialog: true,
                                                        warningText: `Are you sure you want to delete - ${item.short_id.toUpperCase()} - permanently?`,
                                                        fetchString:
                                                          "snapshotStore/fetchCurrentFiles",
                                                        objectName:
                                                          item.short_id,
                                                      },
                                                      on: {
                                                        error: function (
                                                          $event
                                                        ) {
                                                          return _vm.errorMessage(
                                                            $event.error
                                                          )
                                                        },
                                                        deleting: function (
                                                          $event
                                                        ) {
                                                          return _vm.emptySelected(
                                                            !$event.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isSpaceAdmin &&
                                            !_vm.isHomeFilesArea &&
                                            _vm.currentSpaceType !==
                                              _vm.spaceTypes.RESEARCH_SPACE &&
                                            !_vm.isColdStorage &&
                                            !_vm.isSpaceRested
                                              ? [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      attrs: {
                                                        "e2e-delete-file-for-others":
                                                          "",
                                                      },
                                                    },
                                                    [
                                                      _c("delete-button", {
                                                        attrs: {
                                                          objectType: "file",
                                                          buttonName:
                                                            _vm.getDeleteForOthersLabel,
                                                          protocol: "POST",
                                                          "request-body": {
                                                            fids: [item.fid],
                                                            mode: 2,
                                                          },
                                                          apiURL:
                                                            "/files/delete_async",
                                                          isAsyncDeletion: true,
                                                          showDialog: true,
                                                          warningText: `Are you sure you want to delete all distributed versions of - ${item.short_id.toUpperCase()} - from the other instances permanently? The file would still be available for you.`,
                                                          fetchString:
                                                            "snapshotStore/fetchCurrentFiles",
                                                          objectName:
                                                            item.short_id,
                                                        },
                                                        on: {
                                                          error: function (
                                                            $event
                                                          ) {
                                                            return _vm.errorMessage(
                                                              $event.error
                                                            )
                                                          },
                                                          deleting: function (
                                                            $event
                                                          ) {
                                                            return _vm.emptySelected(
                                                              !$event.value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.isDevelopment &&
                                                  _vm.isMasterInstance &&
                                                  !_vm.isSpaceRested
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          attrs: {
                                                            "e2e-delete-file-for-all":
                                                              "",
                                                          },
                                                        },
                                                        [
                                                          _c("delete-button", {
                                                            attrs: {
                                                              objectType:
                                                                "file",
                                                              buttonName:
                                                                "Delete for all",
                                                              protocol: "POST",
                                                              "request-body": {
                                                                fids: [
                                                                  item.fid,
                                                                ],
                                                                mode: 1,
                                                              },
                                                              apiURL:
                                                                "/files/delete_async",
                                                              isAsyncDeletion: true,
                                                              showDialog: true,
                                                              warningText: `Are you sure you want to delete your and all distributed versions of - ${item.short_id.toUpperCase()} - permanently?`,
                                                              fetchString:
                                                                "snapshotStore/fetchCurrentFiles",
                                                              objectName:
                                                                item.short_id,
                                                            },
                                                            on: {
                                                              error: function (
                                                                $event
                                                              ) {
                                                                return _vm.errorMessage(
                                                                  $event.error
                                                                )
                                                              },
                                                              deleting:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.emptySelected(
                                                                    !$event.value
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        },
                        [
                          _vm.pathArray.length > 1
                            ? _c("template", { slot: "body.prepend" }, [
                                _c("tr", [
                                  _c("td"),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "justify-start",
                                          attrs: { text: "", block: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$store.dispatch(
                                                "snapshotStore/setTreeLevel",
                                                {
                                                  snid: _vm.$route.params.snid,
                                                  level:
                                                    _vm.pathArray.length - 1,
                                                }
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "secondary",
                                              },
                                            },
                                            [_vm._v("$unixTraversalUp")]
                                          ),
                                          _vm._v("   "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("td", { attrs: { colspan: "3" } }),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.readme_md_content && !_vm.isSpaceRested
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "d-flex justify-center w-100" },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-3 w-100", attrs: { outlined: "" } },
                  [
                    _c(
                      "v-card-title",
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-markdown"),
                        ]),
                        _vm._v(" README.md "),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c("MarkdownViewer", {
                          attrs: { editorText: _vm.readme_md_content },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }